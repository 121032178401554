var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", xl: "12" } },
            [
              _c("transition", { attrs: { name: "slide" } }, [
                _c(
                  "div",
                  [
                    _c(
                      "b-row",
                      { staticClass: "mb-5" },
                      [
                        _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                          _c(
                            "h1",
                            {
                              class: [
                                `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                                { "is-pwa": _vm.$isPwa() },
                              ],
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t\t\t" +
                                  _vm._s(_vm.FormMSG(10, "Tips of the day")) +
                                  "\n\t\t\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { cols: "12", md: "6" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                staticClass: "mb-0 mt-2",
                                attrs: {
                                  "label-cols": "0",
                                  "label-align-sm": "left",
                                  "label-size": "sm",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  { attrs: { size: "sm" } },
                                  [
                                    _c("b-form-input", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "filterInput",
                                        type: "search",
                                        placeholder: _vm.FormMSG(
                                          25,
                                          "Type to Search"
                                        ),
                                      },
                                      model: {
                                        value: _vm.filter,
                                        callback: function ($$v) {
                                          _vm.filter = $$v
                                        },
                                        expression: "filter",
                                      },
                                    }),
                                    _c(
                                      "b-input-group-append",
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            attrs: { disabled: !_vm.filter },
                                            on: {
                                              click: function ($event) {
                                                _vm.filter = ""
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-times",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        class: `${
                          _vm.$screen.width <= 576 ? "my-project" : ""
                        }`,
                      },
                      [
                        _c(
                          "b-tabs",
                          {
                            attrs: {
                              cards: "",
                              "active-nav-item-class": "font-weight-bold",
                              "active-tab-class": "font-weight-bold",
                            },
                          },
                          [
                            _vm.Tips &&
                            _vm.Tips.length > 0 &&
                            !_vm.isFilmSingleUserFree &&
                            !_vm.isFilmSingleUser
                              ? _c(
                                  "b-tab",
                                  {
                                    attrs: {
                                      title: _vm.FormMSG(
                                        24,
                                        "Tip of the day list"
                                      ),
                                    },
                                  },
                                  [
                                    _c(
                                      "b-row",
                                      _vm._l(
                                        _vm.tipsFiltered,
                                        function (item, index) {
                                          return _c(
                                            "b-col",
                                            {
                                              key: index,
                                              attrs: { cols: "12" },
                                            },
                                            [
                                              _c(
                                                "b-card",
                                                {
                                                  staticClass:
                                                    "mx-auto my-4 shadow card-tip",
                                                },
                                                [
                                                  _c("b-card-text", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mt-2 small",
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-primary",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.categoryName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          item.description
                                                        ),
                                                      },
                                                    }),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _c("loading", {
                                      attrs: {
                                        active: _vm.isLoading,
                                        "is-full-page": true,
                                      },
                                      on: {
                                        "update:active": function ($event) {
                                          _vm.isLoading = $event
                                        },
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.showTipOfDay && _vm.$screen.width >= 576
                              ? _c(
                                  "b-tab",
                                  {
                                    attrs: {
                                      title: _vm.FormMSG(
                                        350,
                                        "Your tip of day"
                                      ),
                                    },
                                  },
                                  [_c("Tipofday")],
                                  1
                                )
                              : _vm._e(),
                            _vm.TgsTips
                              ? _c(
                                  "b-tab",
                                  {
                                    attrs: {
                                      title: _vm.FormMSG(
                                        35,
                                        "TheGreenShot tip of the day list"
                                      ),
                                    },
                                  },
                                  [
                                    _vm.TgsTips.length > 0
                                      ? _c(
                                          "b-row",
                                          _vm._l(
                                            _vm.tgsTipsFiltered,
                                            function (item, index) {
                                              return _c(
                                                "b-col",
                                                {
                                                  key: index,
                                                  attrs: { cols: "12" },
                                                },
                                                [
                                                  _c(
                                                    "b-card",
                                                    {
                                                      staticClass:
                                                        "mx-auto my-4 shadow card-tip",
                                                    },
                                                    [
                                                      _c("b-card-text", [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "mt-2 small",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "text-primary",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.categoryName
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                        _c("div", {
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              item.description
                                                            ),
                                                          },
                                                        }),
                                                      ]),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          1
                                        )
                                      : _c(
                                          "b-row",
                                          [
                                            _c("b-col", [
                                              _c(
                                                "div",
                                                { staticClass: "empty-state" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(
                                                        _vm.FormMSG(
                                                          5528,
                                                          "Currently you have no TGS tip of the day"
                                                        )
                                                      ) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ],
                                          1
                                        ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }